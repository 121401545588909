<template>
  <b-card no-body style="border: 0">
    <b-row no-gutters>
      <b-col cols="auto" class="mr-1">
        <b-form-checkbox
          name="product-id"
          class="product-reveal-checkbox"
          :value="true"
          :unchecked-value="false"
          v-model="isProductUpsellSelected"
        >
        </b-form-checkbox>
      </b-col>
      <b-col>
        <b-row no-gutters style="position: relative; background-color: #f8f8f8">
          <b-col cols="12" lg="4">
            <div class="img-holder">
              <img height="100" width="200" :src="product.images[0]" />
            </div>
          </b-col>
          <b-col
            cols="12"
            lg="8"
            class="px-4 py-4 d-flex flex-column justify-content-between"
          >
            <div>
              <h3 style="letter-spacing: 0.01em; font-size: 18px">
                {{ product.name }}
              </h3>
              <p
                style="font-size: 14px"
                v-html="product.shortdescription.html"
              ></p>
            </div>

            <ul class="list-unstyled list-inline mt-0 mb-0">
              <li v-for="price in product.pricing" :key="price.id">
                <input
                  :value="price.id"
                  :id="price.id"
                  type="radio"
                  :name="price.id"
                  v-model="selectedPricing"
                />
                <label
                  :for="price.id"
                  class="subscription-label mb-0"
                  v-if="price.type === 'one_time'"
                >
                  ${{ (price.unit_amount * 0.01).toFixed(2) }}
                </label>
                <label :for="price.id" class="subscription-label mb-0" v-else>
                  ${{ (price.unit_amount * 0.01).toFixed(2) }}/month
                </label>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "SelectProductUpsell",
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isProductUpsellSelected: false,
      selectedPricing: "",
    };
  },
  watch: {
    isProductUpsellSelected(val) {
      if (val) {
        this.selectedPricing = this.product.pricing[0].id;
      } else {
        this.selectedPricing = "";
        this.$emit("upsell-remove", this.idx);
      }
    },
    selectedPricing(val) {
      if (val) {
        const pricing = this.product.pricing.find((price) => price.id === val);
        this.onRadioUpsellChange({
          upsellId: this.product.id,
          productId: pricing.product,
          pricingId: pricing.id,
          unitAmount: pricing.unit_amount * 0.01,
          productName: this.product.name,
          idx: this.idx,
        });
      }
    },
  },
  methods: {
    onRadioUpsellChange(upsellData) {
      this.$emit("upsell-change", upsellData);
    },
  },
};
</script>

<style lang="scss" scoped>
.img-holder {
  width: 100% !important;
  height: 200px !important;
  border-right: 1px solid #edf2f9;
  background-color: #ebf5f4;
  margin-right: 0 !important;

  @include media-breakpoint-down(md) {
    height: 200px !important;
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
}

.list-inline {
  margin-top: 1.5rem;

  li {
    position: relative;
    width: 50%;
    display: inline-block;

    .subscription-label {
      font-size: 1rem;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    input {
      position: absolute;
      left: -9999px;
    }

    label {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: inline-flex;
        flex: 1 150vw;
        width: 15px;
        height: 15px;
        max-width: 15px;
        border-radius: 15px;
        border: 1px solid $primary;
        margin-right: 10px;

        @include media-breakpoint-up(xxl) {
          width: 15px * $scale-font;
          height: 15px * $scale-font;
          max-width: 15px * $scale-font;
          border-radius: 15px * $scale-font;
          margin-right: 10px * $scale-font;
        }
      }
    }

    input:checked + label {
      &:before {
        background-color: $primary;
      }
    }
  }
}
</style>
