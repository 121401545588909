<template>
  <modal
    id="modalUpsell"
    ref="modalUpsell"
    size="xl"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
    modal-class="root-modal-upsell"
    content-class="content-modal-upsell"
    dialog-class="dialog-modal-upsell"
  >
    <div class="modal-header" style="padding: 0; border: 0">
      <b-button
        style="position: absolute; top: 25px; right: 25px; padding: 2px 0px"
        size="sm"
        variant="link"
        @click="onDenyUpsell"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12" r="11.5" stroke="#1A6A72" />
          <path
            d="M14.903 14.189L12.7638 12.0499L14.903 9.91077L14.1899 9.19773L12.0508 11.3369L9.91162 9.19773L9.19857 9.91077L11.3377 12.0499L9.19857 14.189L9.91162 14.9021L12.0508 12.763L14.1899 14.9021L14.903 14.189Z"
            fill="#1A6A72"
          />
        </svg>
      </b-button>
    </div>
    <b-row
      class="upsell-container-single mt-4"
      v-if="upsellProductList.length === 1"
    >
      <b-col lg class="text-center">
        <div class="align-items-center flex-column d-none d-lg-flex">
          <img
            class="my-5"
            :src="upsellProductList[0].images[0]"
            alt=""
            style="width: 70%"
          />
          <p class="text-center">
            <span class="upsell__remark">
              "{{ upsellProductList[0].metadata.remark }}"
            </span>
            <br />
            <span class="upsell__remarkby">
              -{{ upsellProductList[0].metadata.remarkby }}
            </span>
          </p>
        </div>
      </b-col>
      <b-col
        lg
        class="d-flex flex-column justify-content-center pr-4"
        v-if="isSuccessSectionVisible"
      >
        <div class="align-items-center flex-column d-flex d-lg-none mb-5">
          <img
            class="my-5"
            :src="upsellProductList[0].images[0]"
            alt=""
            style="width: 60%"
          />
          <p class="text-center">
            <span class="upsell__remark">
              "{{ upsellProductList[0].metadata.remark }}"
            </span>
            <br />
            <span class="upsell__remarkby">
              -{{ upsellProductList[0].metadata.remarkby }}
            </span>
          </p>
        </div>
        <h2 class="h1 upsell__header mb-2">Success!</h2>
        <p class="upsell__product-success">
          {{ upsellProductList[0].name }} has been added to your treatment plan
          for your doctor to review.
        </p>
        <b-button @click="closeUpsellModal" pill variant="primary mb-4">
          Close Window
        </b-button>
      </b-col>
      <b-col lg class="d-flex flex-column justify-content-center pr-4" v-else>
        <div class="text-center text-lg-left">
          <h2 class="h1 upsell__header mb-2">
            <span> {{ upsellProductList[0].metadata.header }} </span>
          </h2>
          <p class="mb-5" style="font-size: 18px">
            {{ upsellProductList[0].metadata.headerdescription }}
          </p>
        </div>
        <div class="align-items-center flex-column d-flex d-lg-none mb-5">
          <img
            class="my-5"
            :src="upsellProductList[0].images[0]"
            alt=""
            style="width: 60%"
          />
          <p class="text-center">
            <span class="upsell__remark">
              "{{ upsellProductList[0].metadata.remark }}"
            </span>
            <br />
            <span class="upsell__remarkby">
              -{{ upsellProductList[0].metadata.remarkby }}
            </span>
          </p>
        </div>
        <h3 class="upsell__product-title">
          {{ upsellProductList[0].name }}
        </h3>
        <h4
          class="mb-4 upsell__product-price"
          v-if="upsellProductList[0].pricing.length <= 1"
        >
          <span style="text-decoration: line-through" class="mr-2">
            {{ upsellProductList[0].metadata.strikethroughprice }}
          </span>
          <span
            >${{
              getPrice(upsellProductList[0].pricing[0].unit_amount * 0.01)
            }}</span
          >
        </h4>
        <div class="upsell__product-description">
          <div class="d-flex upsell__description-nav">
            <a
              @click="selectedUpsellNavigation = 'description'"
              :style="
                selectedUpsellNavigation === 'description'
                  ? { color: '#19191c', 'text-decoration': 'none' }
                  : ''
              "
            >
              Description
            </a>
            <span class="mx-2">|</span>
            <a
              @click="selectedUpsellNavigation = 'howtouse'"
              :style="
                selectedUpsellNavigation === 'howtouse'
                  ? { color: '#19191c', 'text-decoration': 'none' }
                  : ''
              "
            >
              How It Works
            </a>
            <span class="mx-2">|</span>
            <a
              @click="selectedUpsellNavigation = 'ingredients'"
              :style="
                selectedUpsellNavigation === 'ingredients'
                  ? { color: '#19191c', 'text-decoration': 'none' }
                  : ''
              "
            >
              Ingredients
            </a>
          </div>
          <p
            class="mb-0 upsell__description-text"
            v-html="upsellProductList[0][selectedUpsellNavigation].html"
          ></p>
          <a
            href="https://help.bywinona.com/en/collections/3079719-blossom-by-winona"
            target="_blank"
            class="upsell__learn-more mb-4 d-inline-block"
          >
            Learn more about {{ upsellProductList[0].name }} by Winona
          </a>
        </div>
        <ul class="list-unstyled list-inline mt-4">
          <li v-for="price in upsellProductList[0].pricing" :key="price.id">
            <input
              :value="price.id"
              :id="price.id"
              type="radio"
              :name="price.id"
              v-model="selectedUpsellPricingId"
            />
            <label
              :for="price.id"
              class="subscription-label mb-0"
              v-if="price.type === 'one_time'"
            >
              ${{ getPrice((price.unit_amount * 0.01).toFixed(2)) }}
            </label>
            <label :for="price.id" class="subscription-label mb-0" v-else>
              ${{ getPrice((price.unit_amount * 0.01).toFixed(2)) }}/month
            </label>
          </li>
        </ul>
        <div
          class="mb-4 d-flex justify-content-between flex-wrap"
          :class="{ 'mt-4': upsellProductList[0].pricing.length <= 1 }"
        >
          <div class="d-flex align-items-center py-2">
            <img
              :src="require('@/assets/images/upsell/prescription.svg')"
              alt=""
              height="30"
              width="30"
              class="mr-2"
            />
            <p class="text-uppercase upsell__icon-info mb-0">
              Prescribed by <br />
              licensed doctors
            </p>
          </div>
          <div class="d-flex align-items-center py-2">
            <img
              :src="require('@/assets/images/upsell/hand-heart.svg')"
              alt=""
              height="30"
              width="30"
              class="mr-2"
            />
            <p class="text-uppercase upsell__icon-info mb-0">
              Made custom <br />
              for your body
            </p>
          </div>
          <div class="d-flex align-items-center py-2">
            <img
              :src="require('@/assets/images/dashboard/heart.svg')"
              alt=""
              height="30"
              width="30"
              class="mr-2"
            />
            <p class="text-uppercase upsell__icon-info mb-0">
              Free shipping, <br />
              Cancel anytime
            </p>
          </div>
        </div>
        <b-button @click="onAcceptUpsell" pill variant="primary mb-4">
          Add to treatment plan
          <span v-if="upsellProductList[0].pricing.length <= 1">
            |
            {{
              parsePrice(
                getPrice(upsellProductList[0].pricing[0].unit_amount * 0.01)
              )
            }}
          </span>
        </b-button>
        <p
          class="text-underline text-center"
          style="cursor: pointer"
          @click="onDenyUpsell"
        >
          No thanks, I would not like to add this to my order.
        </p>
      </b-col>
    </b-row>

    <!-- Multiple products -->
    <b-row
      class="
        upsell-container-multiple
        d-flex
        flex-column
        align-items-center
        mt-4
      "
      v-if="upsellProductList.length > 1"
    >
      <div class="d-flex justify-content-center text-center mb-4">
        <h2 class="h1 mb-3 upsell__header">
          <span class="d-none d-md-block">
            Would you like to try our <br />
            new products?
          </span>
          <span class="d-block d-md-none">
            Would you like to try our new products?
          </span>
        </h2>
      </div>

      <div style="max-width: 800px">
        <SelectProductUpsell
          v-for="(product, i) in upsellProductList"
          :key="product.id"
          :product="product"
          :idx="i"
          @upsell-change="onMultipleUpsellChange"
          @upsell-remove="onMultipleUpsellRemove"
        />
      </div>
      <b-button
        @click="onAcceptUpsell"
        pill
        variant="primary mb-4"
        style="width: 250px"
      >
        Request Prescription
      </b-button>
      <p
        class="text-underline text-center"
        style="cursor: pointer"
        @click="onDenyUpsell"
      >
        No thanks, I would not like to add this to my order.
      </p>
    </b-row>
  </modal>
</template>

<script>
import numeral from "numeral";
import SelectProductUpsell from "@/components/onboarding/checkout/SelectProductUpsell";
import { OnboardingService } from "@/services/onboarding.service";

export default {
  name: "OneClickUpsell",
  components: {
    SelectProductUpsell,
  },
  props: {
    isUpsellModalVisible: {
      type: Boolean,
      default: false,
    },
    upsellFrom: {
      type: String,
      default: "",
      required: true,
    },
    couponid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isModalUpsellVisible: false,
      selectedUpsellPricingId: "",
      selectedUpsells: [],
      selectedUpsellNavigation: "description",
      isSuccessSectionVisible: false,
      couponData: {},
    };
  },
  computed: {
    upsellProductList() {
      return this.$store.state.upsell.upsellList;
    },
    isEveryUpsellNull() {
      return this.selectedUpsells.every((value) => value === null);
    },
  },
  watch: {
    upsellProductList(val) {
      if (val.length > 0) {
        this.selectedUpsells = Array(val.length).fill(null);
        this.$nextTick(() => {
          this.$bvModal.show("modalUpsell");
        });
        if (val[0].pricing.length > 0 && this.selectedUpsells.length === 1) {
          this.selectedUpsellPricingId = val[0].pricing[0].id;
        }
      }
    },
    selectedUpsellPricingId(val) {
      if (val && this.selectedUpsells.length === 1) {
        const pricing = this.upsellProductList[0].pricing.find(
          (price) => price.id === val
        );
        this.onSingleUpsellChange({
          upsellId: this.upsellProductList[0].id,
          productId: pricing.product,
          pricingId: pricing.id,
          unitAmount: pricing.unit_amount * 0.01,
          productName: this.upsellProductList[0].name,
          idx: 0,
        });
      }
    },
    async isUpsellModalVisible(val) {
      if (this.upsellProductList.length > 0) {
        if (val) {
          this.$bvModal.show("modalUpsell");
        } else {
          this.$bvModal.hide("modalUpsell");
        }
      } else {
        // If there's no upsell list, just close the modal
        this.$emit("update:isUpsellModalVisible", false);
      }
    },
  },
  async created() {
    await this.getUpsellProductList();
  },
  async mounted() {
    if (this.couponid && this.couponid.length > 0) {
      const onboarding_blossom_promocodes = [
        "BLACKFRIDAY2022",
        "CYBERMONDAY2022",
      ];
      if (onboarding_blossom_promocodes.includes(this.couponid)) {
        this.$cookies.set("promocode", "BFBLOSSOM10");
        const { data } = await OnboardingService.checkCoupon("BFBLOSSOM10");
        this.couponData = data;
      } else {
        // Get coupon
        const { data } = await OnboardingService.checkCoupon(this.couponid);
        this.couponData = data;
      }
    }
  },
  methods: {
    getPrice(value) {
      if (this.couponData && this.couponData.hasOwnProperty("applies_to")) {
        if (
          // Staging
          this.couponData.applies_to.products[0] == "prod_Jxqgr2Hh9YGwjZ" ||
          // Prod
          this.couponData.applies_to.products[0] == "prod_K0rnRpVxfgGP4y"
        ) {
          // const percentoff =
          //   Number(value) * (this.couponData.percent_off / 100);
          return (Number(value) - this.couponData.amount_off * 0.01).toFixed(2);
        } else {
          // Keep same
          return value;
        }
      }
      // -- For general discounts
      else if (
        this.couponData &&
        Object.keys(this.couponData).length > 0 &&
        !this.couponData.hasOwnProperty("applies_to")
      ) {
        const percentoff = Number(value) * (this.couponData.percent_off / 100);
        return (Number(value) - percentoff).toFixed(2);
      } else {
        return value;
      }
    },
    closeUpsellModal() {
      this.$emit("update:isUpsellModalVisible", false);
    },
    getUpsellProductList() {
      this.$store.dispatch("upsell/getUpsellList");
    },
    onAcceptUpsell() {
      if (!this.isEveryUpsellNull) {
        this.selectedUpsells.forEach((upsellData) => {
          if (upsellData) {
            if (this.couponData && this.couponData.hasOwnProperty("id")) {
              this.$store.dispatch("upsell/acceptUpsell", {
                ...upsellData,
                triggeredfrom: this.upsellFrom,
                couponId: this.couponData.id,
              });
            } else {
              this.$store.dispatch("upsell/acceptUpsell", {
                ...upsellData,
                triggeredfrom: this.upsellFrom,
              });
            }
          }
        });
        this.isSuccessSectionVisible = true;
        this.$emit("remove-banner");
        if ($cookies.get("promocode")) {
          $cookies.remove("promocode");
        }
      }
    },
    onDenyUpsell() {
      this.upsellProductList.forEach((upsellData) => {
        this.$store.dispatch("upsell/denyUpsell", {
          upsellId: upsellData.id,
        });
      });
      this.closeUpsellModal();
    },
    onSingleUpsellChange(upsellData) {
      this.selectedUpsells.splice(upsellData.idx, 1, upsellData);
    },
    onMultipleUpsellChange(upsellData) {
      this.selectedUpsells.splice(upsellData.idx, 1, upsellData);
    },
    onMultipleUpsellRemove(idx) {
      this.selectedUpsells.splice(idx, 1, null);
    },
    parsePrice(price) {
      return numeral(price).format("$0,0[.]00");
    },
  },
};
</script>

<style lang="scss">
@include media-breakpoint-down(md) {
  .modal.root-modal-upsell {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .modal-dialog.dialog-modal-upsell {
    margin: 0;
    max-width: 100%;
  }

  .modal-content.content-modal-upsell {
    border-radius: 0;
    min-height: 100vh;
  }
}
</style>

<style lang="scss" scoped>
.upsell-container-single {
  .upsell__header {
    font-size: 30px;
    line-height: 30px;
    color: #0c3523;
    font-family: $heading-font;
  }

  .upsell__remark,
  .upsell__remarkby {
    line-height: 34px;
    color: #0c3523;
    font-family: $heading-font;
  }

  .upsell__remark {
    font-size: 22px;
  }

  .upsell__remarkby {
    font-size: 18px;
  }

  .upsell__product-title {
    font-size: 32px;
    margin-bottom: 0.25rem;

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }

  .upsell__product-price {
    font-size: 24px;
    color: #1a6a72;
    letter-spacing: 0.02rem;

    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }

  .upsell__product-success {
    font-size: 20px !important;
    line-height: 28px !important;
  }

  .upsell__product-description {
    font-size: 20px;
    line-height: 28px;
    border-top: 0.75px solid #333;
    border-bottom: 0.75px solid #333;

    .upsell__description-nav {
      font-size: 16px;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
    }

    .upsell__description-text > p {
      margin-bottom: 24px !important;
    }

    .upsell__learn-more,
    .upsell__description-nav a {
      cursor: pointer;
      font-size: 16px;
      color: #a783ff;
      text-decoration: underline;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }
  }

  .upsell__icon-info {
    font-size: 11px;

    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }
  }
}

.upsell-container-multiple {
  .upsell__header {
    font-size: 30px;
    line-height: 30px;
    color: #0c3523;
    font-family: $heading-font;
  }
}

.list-inline {
  margin-top: 1.5rem;

  li {
    position: relative;
    width: 50%;
    display: inline-block;

    .subscription-label {
      font-size: 1.25rem;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    input {
      position: absolute;
      left: -9999px;
    }

    label {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: inline-flex;
        flex: 1 150vw;
        width: 15px;
        height: 15px;
        max-width: 15px;
        border-radius: 15px;
        border: 1px solid $primary;
        margin-right: 10px;

        @include media-breakpoint-up(xxl) {
          width: 15px * $scale-font;
          height: 15px * $scale-font;
          max-width: 15px * $scale-font;
          border-radius: 15px * $scale-font;
          margin-right: 10px * $scale-font;
        }
      }
    }

    input:checked + label {
      &:before {
        background-color: $primary;
      }
    }
  }
}
</style>
